<template>
  <div class='my-8 py-8 px-8 relative'
    :class='verticalPaddingByMultipler'
    :style='`background-color: ${sponsorBackgroundColor}33`'>
    <div 
      class='uppercase flex flex-row items-center gap-x-2 text-lg'
      :style='sponsorTitleStyle'>
      <component :is='sponsorTitleIcon' size='20' /> 
      <h2>{{ sponsors.levelName }}</h2>
    </div>
    <div class='flex flex-row flex-wrap justify-start gap-2 my-4 lg:my-8'>
      <div v-for='sponsor in sponsors.sponsors'
        :key='`sponsor-${sponsors.levelName}-${sponsor.id}`'
        class='bg-white rounded-md border p-4 flex flex-row justify-center items-center relative h-48 lg:h-auto'
        :class='`${sponsorLogoImageHoverClass(sponsor)}`'
        :style='`${sponsorLogoImageWidthStyle}; ${sponsorLogoImageHeightStyle}; border-top: 4px solid ${sponsorBackgroundColor};`'
        @click='goToSponsorDetail(sponsor)'>
        <img v-if='sponsor.me.stamped'
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/stamped_icon.svg' 
          class='absolute top-2 right-2 h-6 lg:h-8'>
        <img :src='sponsor.logoImageUrl' 
          style='max-width: 90%; max-height: 80%;'>
      </div>
    </div>
  </div>
</template>

<script>
import { DiamondIcon, StarIcon } from 'vue-tabler-icons'
import { BadgeCheckIcon }        from '@vue-hero-icons/outline'
import ContentBackgroundCover    from '@/components/ContentBackgroundCover.vue'

export default {
  name: 'SponsorsByLevel',
  components: {
    BadgeCheckIcon,
    ContentBackgroundCover,
    DiamondIcon,
    StarIcon,
  },
  props: [
    'sponsors',
    'configurations',
    'sizingMultiplier',
  ],
  computed: {
    sponsorTitleIcon () {
      return (this.configurations && this.configurations.levelIcon) ? `${this.configurations.levelIcon}-icon` : ''
    },
    sponsorTitleStyle () {
       return (this.configurations) ? `${this.configurations.titleStyle}` : 'color: #555; font-weight: 500;'
    },
    sponsorBackgroundColor () {
       return (this.configurations) ? `${this.configurations.backgroundColor}` : 'background-color: #FAFAFA;'
    },
    sponsorLogoImageWidthStyle () {
      let fullPagePercentage = (this.configurations) ? this.configurations.widthPercentage : 50
      let widthPercentage = (window.innerWidth >= 1024) ? fullPagePercentage : 50
      return `width: calc(${widthPercentage}% - 0.5rem);`
    },
    sponsorLogoImageHeightStyle () {
      let fullPagePercentage = (this.configurations && this.configurations.widthPercentage) ? this.configurations.widthPercentage : 50
      let heightCalculated = fullPagePercentage / 100
      let sponsorBoxHeightPx = (this.configurations && this.configurations.sponsorBoxHeightPx) ? this.configurations.sponsorBoxHeightPx : 460 * heightCalculated
      return `height: ${sponsorBoxHeightPx}px;`
    },
    verticalPaddingByMultipler () {
      return (this.sizingMultiplier) ? `lg:py-${parseInt(this.sizingMultiplier * 16)}` : `lg:py-16`
    },
  },
  methods: {
    goToSponsorDetail (sponsor) {
      if (sponsor.configurations.actionType === 'open_web') {
        window.open(sponsor.configurations.actionUrl, '_blank')
      } else if (sponsor.configurations.actionType === 'open_booth') {
        this.$router.push({name: 'Sponsor', query: { sponsor_id: sponsor.id }})
      } else if (sponsor.configurations.actionType === 'no_details') {
        // do nothing
      }
    },
    sponsorLogoImageHoverClass (sponsor) {
      return sponsor.configurations.actionType === 'no_details' ? '' : 'hover:shadow-md cursor-pointer'
    },
  },
}
</script>

