<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-8'>
    <sidebar-page-navigation class='flex-shrink-0'>
    </sidebar-page-navigation>
    <div class='px-2 lg:px-0'>
      <sponsors-by-level v-for='(sponsorsByLevel, index) in sponsorList'
        :key='`sponsors-by-level-${sponsorsByLevel.levelName}`'
        :ref='`sponsor-list-container-${index}`'
        :sponsors='sponsorsByLevel'
        :configurations='configurationsByLevel(index)'
        :sizing-multiplier='sponsorsConfigurations.sizingMultiplier' />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions }  from 'vuex'
import SponsorsByLevel from '@/components/koa2022/SponsorsByLevel.vue'
import SidebarPageNavigation from '@/components/koa2022/SidebarPageNavigation.vue'

export default {
  name: 'Sponsors',
  components: {
    SponsorsByLevel,
    SidebarPageNavigation,
  },
  props: [

  ],
  computed: {
    ...mapState('sponsors', [
      'sponsorList',
    ]),
    ...mapState('events', [
      'sponsorsConfigurations',
    ]),
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('sponsors', [
      'getSponsors',
    ]),
    configurationsByLevel (levelNumber) {
      return this.sponsorsConfigurations.levels.find(level => level.levelIndex === (levelNumber+1))
    },
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    this.getSponsors('Sponsors').then(() => {
      this.hideFullpageLoadingIndicator()
    })
  },
}
</script>

